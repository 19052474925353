<template>


  <CRow>
 
   
    <CCol sm="12" style="margin-bottom:20px;">

     <CButton style="width: 250px; float:right" @click="nueva_referencia()" block color="facebook">
        Generar nueva referencia
      </CButton>
    </CCol>
    
    <CModal
            title="Nueva Referencia"
            color="danger"
            :show.sync="warningModal"
    >
      <CRow>
        <CCol sm="12">

          <CSelect
                  label="Carrera"
                  :options="carreras"
                  placeholder="Seleccione una Carrera"
                  :value.sync="carrera_select"
                  @change="get_carreras2()"

          />
           <CAlert color="warning" >
                 <strong>Aviso Importante:</strong> Selecciona una segunda opción de carrera. En caso de que la opción indicada como primera no se aperture, se considerará la segunda para continuar con el proceso de admisión.
           </CAlert>
           <CSelect
                  label="Segunda Opcion de Carrera"
                   placeholder="Seleccione una Carrera"
                  :options="carreras2"
                  :value.sync="carrera_select2"
                  

          />
        </CCol>
        <CCol sm="12">
        <CSelect
                label="Fecha"
                 placeholder="Seleccione fecha"
                 :options="fechas"
                 :value.sync="fecha_select"
                
        />
       
        </CCol>
     <!-- <CAlert color="danger">
                 <strong>Aviso Importante:</strong> a partír del 19 al 29 de Mayo, No se podrá generar referencias de pago admisión.
           </CAlert> -->

      </CRow>
      <CButton @click="generar_referencia()" block color="facebook">
        Generar 
      </CButton>
      <template #footer-wrapper>
        <span></span>
      </template>
    </CModal>


    <CCol col="12" md="12">
            <CTableWrapper
                    :items="getShuffledUsersData()"
                    small
                    caption="Referencias Generadas"
            />
          </CCol>
<CCol sm="3">

</CCol>
    <CCol sm="6">
      <CCard>
        <CCardHeader>
          <strong>Pago</strong>
        </CCardHeader>

        <CCardBody>
           <!--CAlert color="warning" >
                 <strong>Aviso Importante:</strong> Estimado aspirante, te recordamos que el último día para realizar tu pago de ficha es el día martes 13 de julio del 2021.
           </CAlert-->

          <!-- Bootstrap Vue has some problems with Inline forms that's why we use some standard bootstrap classes -->
          <CForm inline>
            <CRow>
              <CCol sm="12" style="text-align: center">
                <small>Acuda al banco BBVA a realizar el pago, una vez validado su pago, se le avisará por correo para continuar con el proceso de admisión,
                  en caso de que su referencia caduque, regrese a este sitio para volver a generar una nueva referencia de pago.
                  &nbsp;</small>
                <br/>
              </CCol>

              <!-- <CCol sm="12" style="text-align: center; margin-top: 20px;">
                <p>
                  <CButton  style="width: 200px; margin-left: auto; margin-right: auto" color="success" @click="salir()" block>Salir</CButton>
                </p>

              </CCol> -->


            </CRow>




          </CForm>
        </CCardBody>
        <CCardFooter>
        </CCardFooter>
      </CCard>
    </CCol>
  </CRow>


</template>

<script>
  import CTableWrapper from './Table.vue'

export default {
  name: 'referenciaBancaria',
  components: { 
    CTableWrapper
       },
  data () {
    return {
      carrera_select: '1',
   // carrera_select: 'seleccione una carrera',      
      carrera_select2: '',
      ya_generada: 0,
      fecha_select: '',
      ocultar_boton: '0',
      carreras: [],
      carreras2:[],
      fechas: [],
      warningModal: false,
      id:localStorage.getItem('id'),
      usersData: [
        { CIE: '1092669', Referencia: '_', Cantidad: '_', Caducidad: '_', Estatus: 'Pendiente de pago'}
      ],
    }
  },
  created() {
    this.get_referencias();
    this.get_carreras();
    this.get_fechas();



  },
  methods: {

    get_referencias(){
      this.$http.get("referenciasCandidato/" + this.id).then(
              response => {
                // console.log(response.data)
                this.usersData = response.data.referencias;
                if(this.usersData.length>0){
                  this.ocultar_boton = 1

                }else{

                    this.nueva_referencia();

                }
                if(this.usersData[0].Estatus == 0){
                  this.usersData[0].Estatus = "Pendiente de pago"
                }
                if(this.usersData[0].Estatus == 1){
                  this.usersData[0].Estatus = "Pagado"
                }
                //console.log(this.kardex);
              },
              error => {
                this.StepError(error);
              }
      );
    },

    getShuffledUsersData () {
      return this.shuffleArray(this.usersData.slice(0))
    },
    shuffleArray (array) {
      for (let i = array.length - 1; i > 0; i--) {
        let j = Math.floor(Math.random() * (i + 1))
        let temp = array[i]
        array[i] = array[j]
        array[j] = temp
      }
      return array
    },

    generar_referencia(){

      // console.log(this.carrera_select); return false;
      if(this.carrera_select2 === ""){
        
        alert('Seleccione una segnda opción de carrera');
        return false;
      }
      this.$http.post('/generarreferenciaadmision', {
        id: this.id,
        carrera: this.carrera_select,
        carrera2: this.carrera_select2,
        fecha: this.fecha_select,
        // fecha: 6,

      }).then(response => {
          this.warningModal = false;
          this.get_referencias()
        open('https://cdn.upbicentenario.edu.mx/api/pdf_pago_ficha/' + this.id)
      })

//  window.open
    },
    nueva_referencia(){

      this.carreras2=[];

      this.warningModal = true;
    },
    get_carreras(){
      this.$http.get("carrerasAdmision").then(
              response => {
                this.carreras = response.data.carrerasAdmision
                //console.log(this.kardex);
              },
              error => {
                this.StepError(error);
              }
      );
    },

get_carreras2(){ 

   let carreras2=[];
    let carrera_value=this.carrera_select;

       this.carreras.forEach(function(item){ 
              if(parseInt(carrera_value)!==item.value){
                carreras2.push(item);
              }
       })

       this.carreras2=carreras2;

},

    get_fechas(){
      this.$http.get("fechasAdmision").then(
              response => {
                // console.log(response.data);
                this.fechas = response.data.fechasAdmision
                //console.log(this.kardex);
              },
              error => {
                this.StepError(error);
              }
      );
    },

  }
}
</script>
